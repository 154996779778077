import React from 'react';
import styles from './custom-toast.module.scss';
import cs from 'classnames';
import CheckIcon from '../icons/check';
import CloseIcon from '../icons/close';
import WarningIcon from '../icons/warning';
import InfoIcon from '../icons/info';

export enum ToastType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
}

interface Props {
  title: string | JSX.Element;
  message: string | JSX.Element;
  type?: ToastType;
}

const CustomToast = ({
  title,
  message,
  type = ToastType.INFO,
}: Props) => {
  const getToastIcon = (iconType: string): JSX.Element | undefined => {
    switch (iconType) {
      case ToastType.SUCCESS:
        return <CheckIcon />;
      case ToastType.WARNING:
        return <WarningIcon />;
      case ToastType.DANGER:
        return <CloseIcon />;
      case ToastType.INFO:
        return <InfoIcon />;
      default:
        return <InfoIcon />;
    }
  };
  return (
    <div className={cs(styles.notification, styles[type])}>
      <div className={styles.icon}>{getToastIcon(type)}</div>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.message}>{message}</div>
      </div>
    </div>
  );
};

export default CustomToast;
