import React, { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import eyeImg from './eye.svg';
import eyeHideImg from './eye-hide.svg';
import styles from './password-field.module.scss';

interface Props {
  id: string;
  label?: string;
  className?: string;
  onChange: (val: any, fieldName: string) => void;
  value: string;
  placeholder?: string;
  inputProps?: object;
}

interface VisibilityPassword {
  img: string;
  style: {
    backgroundColor: string;
    borderColor?: string;
    color?: string;
  };
}

const imgMap: { [key: string]: VisibilityPassword } = {
  show: {
    img: eyeImg,
    style: {
      color: 'rgba(0, 35, 71, 1)',
      backgroundColor: 'transparent',
    },
  },
  hide: {
    img: eyeHideImg,
    style: {
      backgroundColor: 'transparent',
    },
  },
};

const PasswordField = ({ id, label, onChange, placeholder = '', className = '', value = '', inputProps = {} }: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const toggledImg: VisibilityPassword = showPassword ? imgMap['hide'] : imgMap['show'];
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onChangeHandler = (e: any) => {
    const value = e.target.value;
    onChange(value, e.target.name);
  };
  return (
    <div className={styles.passwordWrapper}>
      { label && (
        <label className={styles.label} htmlFor={id}>
          {t(label)}
        </label>
      )}
      <input
        className={cn(styles.input, className)}
        type={showPassword ? 'text' : 'password'}
        id={id}
        onChange={onChangeHandler}
        value={value}
        name={id}
        placeholder={placeholder}
        {...inputProps}
      />
      <img onClick={toggleShowPassword} src={toggledImg.img} alt="" style={toggledImg.style} />
    </div>
  );
};

export default PasswordField;
