import React from 'react';

const InfoIcon = () => (
  <svg viewBox="27 15 13 33.03">
    <path d="M36 44.030v-18h-8v4h4v14h-5v4h13v-4z" fill="#000000" />
    <path
      d="M37 19.015c0 2.217-1.798 4.015-4.015 4.015s-4.015-1.798-4.015-4.015c0-2.217 1.798-4.015 4.015-4.015 2.217 0 4.015 1.798 4.015 4.015z"
      fill="#000000"
    />
  </svg>
);

export default InfoIcon;
