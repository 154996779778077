import React from 'react';
import { useTranslation } from 'react-i18next';
import fullLogo from './assets/full-logo.svg';
import logo from './assets/logo.png';

import styles from './login-container.module.scss';
import { FullRensonLogo } from '@openmotics/react-sdk';

interface Props {
  text?: React.ReactNode;
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
}

const LoginContainer = ({ text, title, subTitle, children }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.leftBlock}>
        <div className={styles.background}/>
        <div>
          <FullRensonLogo width={300} height={300} color={'#ffffff'} />
        </div>
      </div>

      <div className={styles.rightBlock}>
        <div className="login-wrapper">
          <div className={styles.header}>
            <img src={logo} />
            <h1>{title}</h1>
            <span>{subTitle}</span>
          </div>

          <div className={styles.form}>
            {children}
          </div>

          <div className={styles.footer}>
            {text}
          </div>
        </div>
      </div>
    </div>

  );
};

export default LoginContainer;
