import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface InstallationState {
  currentInstallation: number | null
  currentGateway: number | null
  gatewayFeatures: string[]
}

const initialState: InstallationState = {
  currentInstallation: null,
  currentGateway: null,
  gatewayFeatures: [],
};

const installationSlice = createSlice({
  name: 'installation',
  initialState,
  reducers: {
    setInstallation(state, action) {
      state.currentInstallation = action.payload;
    },
    setGateway(state, action) {
      // state mutation is possible in createSlice, because it uses ImmerJS internally.$
      state.currentGateway = action.payload;
    },
    setGatewayFeatures(state, action) {
      state.gatewayFeatures = action.payload;
    },
  },
});

export const selectCurrentInstallation = (state: RootState) => state.installation.currentInstallation;
export const selectCurrentGateway = (state: RootState) => state.installation.currentGateway;
export const selectCurrentGatewayFeatures = (state: RootState) => state.installation.gatewayFeatures;


export const { setInstallation, setGateway, setGatewayFeatures } = installationSlice.actions;
export default installationSlice.reducer;
