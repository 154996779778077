import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationError } from '../../../helpers/form-validators/validators';
import styles from './error-message.module.scss';

interface Props {
  errors: ValidationError;
  translationKey?: string;
  fieldName?: string;
}

const ErrorMessage = ({ errors, translationKey, fieldName }: Props) => {
  const { t } = useTranslation();
  if (!errors) { return null; }
  const errorList = Object.entries(errors);
  return (
    <div className={styles.messageContainer}>
      {errorList.map(([key, val]) => {
        const { condition } = val;
        return (
          <Fragment key={key}>
            <span className={styles.errorMessage}>
              {t(`${translationKey!}.${key}`, { field: fieldName!, condition: condition })}
            </span>
          </Fragment>
        );
      })}
    </div>
  );
};

export default ErrorMessage;
