import React from 'react';

const WarningIcon = () => (
  <svg viewBox="18 10.75 4 19.25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 12L20 22" stroke="#FFAF31" strokeWidth="2.5" strokeLinecap="round" />
    <circle cx="20" cy="28" r="2" fill="#FFAF31" />
  </svg>
);

export default WarningIcon;
