import { Trans, useTranslation } from 'react-i18next';
import PrimaryButton from '../../components/button/PrimaryButton';
import { FullRensonLogo } from '@openmotics/react-sdk';
import React, { useState } from 'react';
import cn from 'classnames';
import Dropdown from '../../components/dropdown/dropdown';
import CloseIcon from '../../components/icons/close';

import openmotics from './openmotics.svg';
import rensonOpenmotics from './assets/renson-openmotics.svg';
import styles from './splash.module.scss';

interface Props {
  onClickOpenmotics: () => void;
  onClickRenson: () => void;
}

const Splash = ({ onClickOpenmotics, onClickRenson }: Props) => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setLanguage] = useState(i18n.resolvedLanguage);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const options = [
    { value: 'nl', label: 'NL' },
    { value: 'en', label: 'EN' },
    { value: 'fr', label: 'FR' },
  ];

  const onChangeLanguage = (value: string|number|undefined) => {
    i18n.changeLanguage(value as string);
    setLanguage(value as string);
  };

  const onShowPopup = (e: any) => {
    e.preventDefault();
    setShowPopup(true);
  };

  return (
    <>
      <div className={styles.background}>
        <div className={styles.mobileBackground} />
        <div className={styles.langSwitch}>
          <Dropdown options={options} value={currentLanguage} onClick={onChangeLanguage} />
        </div>

        <div className={styles.centered}>
          <div className={styles.content}>

            <h1>
              {t('splash.title')}
            </h1>
            <p>
              {t('splash.text')}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              &nbsp;<a onClick={onShowPopup}>{t('splash.moreInfo')}</a>
            </p>

            <div className={styles.actions}>
              <div className={styles.action}>
                <div className={styles.logo}>
                  <FullRensonLogo height={40}/>
                </div>

                <PrimaryButton
                  label={t('splash.signInWithRenson')}
                  className={styles.btnRenson}
                  onClick={onClickRenson}
                />
              </div>
              <div className={styles.action}>
                <div className={cn(styles.openmoticsLogo, styles.logo)}>
                  <img src={openmotics} alt="openmotics logo"/>
                  OpenMotics
                </div>

                <PrimaryButton
                  label={t('splash.signInWithOpenmotics')}
                  className={styles.btnOpen}
                  onClick={onClickOpenmotics}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        showPopup && (
          <div className={styles.wrapPopup}>
            <div className={styles.popup}>
              <span onClick={() => setShowPopup(false)} className={styles.close}><CloseIcon/></span>
              <div>
                <img src={rensonOpenmotics} alt="renson and openmotics" />
              </div>
              <h2>{t('splash.popup.title')}</h2>
              <p>
                <Trans i18nKey={'splash.popup.content'} components={{ br: <br />, b: <b /> }} />
              </p>
              <button className={styles.gotIt} onClick={() => setShowPopup(false)}>
                {t('splash.popup.gotIt')}
              </button>
            </div>
          </div>
        )
      }

    </>
  );

};

export default Splash;
