import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { emptyOpenmoticsApi } from './features/api/api';
import { baseConfigApi } from './features/config-api/api';
import installationReducer from './features/installation/installationSlice';
import menuReducer from './features/menu-slice';
import Shared from '../src/components/shared';
import { realtimeReducer } from '@openmotics/react-sdk/dist/api';
import { rtkQueryErrorLogger } from './features/error-handling/error-middleware';

const combinedReducer = combineReducers({
  [emptyOpenmoticsApi.reducerPath]: emptyOpenmoticsApi.reducer,
  [baseConfigApi.reducerPath]: baseConfigApi.reducer,
  installation: installationReducer,
  menu: menuReducer,
  realtime: realtimeReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }
  return combinedReducer(state, action);
};


export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(emptyOpenmoticsApi.middleware, baseConfigApi.middleware, rtkQueryErrorLogger),
});

Shared.dispatch = store.dispatch;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
