import React from 'react';
import cn from 'classnames';
import Popup from 'reactjs-popup';
import styles from './dropdown.module.scss';

interface Props {
  options: (close: () => void) => React.ReactNode;
  extraContent?: (close: () => void) => React.ReactNode;
  trigger: JSX.Element;
  style?: React.CSSProperties;
  popupProps?: object;
}

const DropdownBase = ({ options, extraContent, trigger, style, popupProps = {} }: Props) => {
  return (
    <Popup trigger={trigger} {...popupProps}>
      {(close: () => void) => (
        <>
          <div className={cn(styles.popup, { [styles.hasExtra]: !!extraContent })} style={style}>
            {options(close)}
          </div>
          {extraContent && <div className={styles.extra}>{extraContent(close)}</div>}
        </>
      )}
    </Popup>
  );
};

export default DropdownBase;
