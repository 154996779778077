import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Storage } from '../../../src/components/storage';
import Shared from '../../../src/components/shared';
const getBaseUrl = () => Shared.settings.api_root;


const rawBaseQuery = (baseUrl: string) => fetchBaseQuery({
  baseUrl: getBaseUrl(),
  prepareHeaders(headers) {
    const token = Storage.getItem('token');
    headers.set('Authorization', `Bearer ${token}`);

    return headers;
  },
});

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseUrl = getBaseUrl();

  if (!baseUrl) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No baseUrl provided to Redux ToolKit Query',
      },
    };
  }

  return rawBaseQuery(baseUrl)(args, api, extraOptions);
};

// Define a service using a base URL and expected endpoints
export const baseConfigApi = createApi({
  reducerPath: 'config-api',
  baseQuery: dynamicBaseQuery, // dynamically fill the base api path for staging or production.,
  endpoints: () => ({}),
});
