import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './input-field.module.scss';
import validate, { Touched, ValidationError } from '../../helpers/form-validators/validators';
import ErrorMessage from './components/error-message';

interface Props {
  id: string;
  label?: string;
  className?: string;
  type?: string;
  onChange: (val: string, fieldName: string, errors?: ValidationError | null, touched?: Touched) => void;
  placeholder?: string;
  value: string;
  showLabel?: boolean;
  wrapperClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  inputProps?: object;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  testId?: string;
}

const InputField = ({
  id,
  label,
  className = '',
  wrapperClassName = '',
  labelClassName = '',
  type = 'text',
  onChange,
  placeholder = '',
  value,
  disabled = false,
  inputProps = {},
  required,
  minLength,
  maxLength,
  min,
  max,
  testId,
}: Props) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [val, setVal] = useState<string>(value);
  const [isTouched, setIsTouched] = useState({});
  const [inputFieldErrors, setInputFieldErrors] = useState<ValidationError>({});

  let validatorList: {} = {};
  if (required || minLength || maxLength || min || max) {
    const build = { ...validatorList, required, minLength, maxLength, min, max };
    validatorList = build;
  }

  useEffect(() => {
    setVal(value);
  }, [value]);

  const onChangeHandler = (e: any) => {
    const { value, name } = e.target;
    setVal(value);
    setIsTouched((current) => { return { ...current, [name]: true }; });
    if (Object.keys(validatorList).length > 0) {
      const validationErrors = validate({ validators: validatorList }, value);
      if (validationErrors) {
        if (Object.keys(validationErrors!)?.length > 0) {
          const updateErrors = { ...inputFieldErrors, ...validationErrors };
          setInputFieldErrors(updateErrors);
          setIsValid(false);
        } else {
          setInputFieldErrors({});
          setIsValid(true);
        }
        onChange(value, name, validationErrors, isTouched);
      }
    } else { // no validation config set
      onChange(value, name, isTouched);
    }
  };

  return (
    <>
      <div className={cn(styles.inputWrapper, wrapperClassName)}>
        {label && (
          <label className={cn(styles.label, isTouched && !isValid ? styles.error : '', labelClassName)} htmlFor={id}>
            {t(label)}
          </label>
        )}
        <input
          data-testid={testId}
          className={cn(styles.input, isTouched && !isValid ? styles.error : '', className)}
          type={type}
          id={id}
          onChange={onChangeHandler}
          placeholder={placeholder}
          value={val}
          disabled={disabled}
          name={id}
          {...inputProps}
        />
        <ErrorMessage errors={inputFieldErrors!} translationKey='general.forms.errors' fieldName={id} />
      </div>
    </>
  );
};

export default InputField;
