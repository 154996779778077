import React from 'react';
import cn from 'classnames';
import styles from './Button.module.scss';
import { SpinnerDefault } from '@openmotics/react-sdk';

export interface Props {
  label?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  spinnerClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  testId?: string;
}

const Button = ({
  label,
  onClick,
  type = 'button',
  className = '',
  spinnerClassName = '',
  disabled = false,
  isLoading = false,
  testId,
  children,
}: Props) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={cn(styles.button, className)}
      disabled={disabled}
      data-testid={testId}
    >
      {!isLoading ? label || children : <SpinnerDefault className={spinnerClassName} />}
    </button>
  );
};

export default Button;
