import React from 'react';
import cn from 'classnames';
import Button, { Props as ButtonProps } from './Button';
import styles from './Button.module.scss';


const PrimaryButton = ({
  label = '',
  onClick,
  type = 'button',
  className = '',
  disabled = false,
  isLoading = false,
  spinnerClassName = '',
  children,
}: ButtonProps) => {
  return (
    <Button
      className={cn(styles.primary, className)}
      label={label}
      spinnerClassName={spinnerClassName}
      type={type}
      isLoading={isLoading}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
