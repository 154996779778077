import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from '../store';
import LoginPage from '../pages/login-page/login-page';
import { Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const getAuthorityFromClientId = (clientId: string) => {
  if (clientId === '02d7e200-636b-4857-96e2-825bdfc5b338') {
    return 'rensonpublic';
  } else if (clientId === '40abd0dd-95f7-46d3-89e4-de36864f2b83') {
    return 'mynubob2c';
  } else {
    return 'mynubob2cdev';
  }
};

const getMsalConfig = (clientId: string): Configuration => {
  const auth = getAuthorityFromClientId(clientId);

  return ({
    auth: {
      clientId,
      authority: `https://${auth}.b2clogin.com/${auth}.onmicrosoft.com/B2C_1A_Smart_HRD_SI_R1/`,
      knownAuthorities: [auth + '.b2clogin.com'],
      // eslint-disable-next-line no-restricted-globals
      redirectUri: location.origin + '/',
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
      },
    },
  });
};

export let msalInstance: PublicClientApplication | null = null;
let msalInstanceResolver: ((value: PublicClientApplication) => void) | null = null;

export const setMsalInstance = (clientId: string) => {
  msalInstance = new PublicClientApplication(getMsalConfig(clientId));
  if (msalInstanceResolver !== null) {
    msalInstanceResolver(msalInstance);
  }
};

export const waitForMsalInstance = (): Promise<PublicClientApplication> => {
  return new Promise((resolve, reject) => {
    if (msalInstance !== null) {
      resolve(msalInstance);
    } else {
      msalInstanceResolver = resolve;
    }
  });
};

export const attach = async (onLoggedIn: (token: string) => Promise<any>) => {
  const instance = await waitForMsalInstance();
  return ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <MsalProvider instance={instance}>
          <LoginPage onLoggedIn={onLoggedIn}/>
        </MsalProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('react-login-root'),
  );
};


export const detach = () => {
  // @ts-ignore
  return ReactDOM.unmountComponentAtNode(document.getElementById('react-login-root'));
};
