import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = {
  isOpen: false,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuState(state, action) {
      state.isOpen = action.payload;
    },
  },
});

export const selectMenuState = (state: RootState) => state.menu.isOpen;

export const { setMenuState } = menuSlice.actions;
export default menuSlice.reducer;
