import React from 'react';
import ArrowDown from '../icons/arrow-down';
import DropdownBase from './dropdown-base';
import styles from './dropdown.module.scss';
import cn from 'classnames';

interface Props {
  options: Array<{value: string|number|undefined; label: string|React.ReactNode;}>
  value: string | number | undefined;
  onClick: (value: string|number|undefined) => void;
  maxHeight?: number;
  extraContent?: (close: () => void) => React.ReactNode;
  triggerNode?: JSX.Element;
  position?: 'bottom center' | 'top center';
}

const Dropdown = ({ value, options, maxHeight = 256, onClick, extraContent, triggerNode, position = 'bottom center' }: Props) => {
  const valueLabel = options.find(o => o.value === value)?.label;

  const trigger = triggerNode ? triggerNode : (
    <div className={styles.container}>
      <span className={styles.current}>{valueLabel}</span> <ArrowDown />
    </div>
  );

  return (
    <DropdownBase
      trigger={trigger}
      options={(close) => (
        options.map(option => (
          <div
            key={option.value ?? 'none'}
            className={cn(styles.option, option.value ?? styles.none)}
            onClick={(e) => { close(); onClick(option.value); }}
          >
            {option.label}
          </div>
        ))
      )}
      extraContent={extraContent}
      style={maxHeight ? { maxHeight } : {}}
      popupProps={{
        position,
      }}
    />
  );
};

export default Dropdown;
