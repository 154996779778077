export interface Rule {
  validator: string;
  validatorValue: string | number | boolean;
}
export interface ValidationRule {
  rule: Rule;
  fieldValue: string;
}

export interface ValidatorList {
  validators: object;
}

export interface ValidatorSetting {
  [key: string]: any;
}

export interface ValidationError {
  [key: string]: any;
}

export type FieldValidationResult = {
  [key: string]: ValidationError;
};

export interface FormError {
  fieldName: string;
  errors: ValidationError[];
}

export interface Touched {
  [key: string]: boolean;
}

const isEmptyValue = (value: any): boolean => {
  return value == null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
};

const isValidNumber = (value: any): boolean => {
  const parsedValue = parseInt(value, 10);
  return !isNaN(parsedValue);
};

const isValidTimeString = (value: string) => {
  return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value); //^([0-1][0-9]|2[0-3]):([0-5][0-9])$
};

const isValidDate = (value: Date) => {
  return (value instanceof Date) && value.toString() !== 'Invalid Date';
};

function fromEntriesToObject(object: any, [key, value]: any) {
  return Object.assign(object, { [key]: value });
}

const getConfiguredValidators = (validationRulesList: ValidatorList) => {
  return Object.entries(validationRulesList.validators)
    .filter(([key, value]) => {
      if (value !== null && value !== undefined) {
        const validator = [key, value];
        return validator;
      }
    })
    .reduce(fromEntriesToObject, {});
};

const validate = (
  validationRulesList: ValidatorList,
  fieldValue: string | boolean | number | Date,
): ValidationError | null => {
  let validationErrors: ValidationError = {};
  let validationConfig: ValidatorList = { validators: getConfiguredValidators(validationRulesList) };
  const validators = Object.entries(validationConfig.validators);
  validators.forEach(([key, value]) => {
    switch (key) {
      case 'required':
        if (isEmptyValue(fieldValue)) {
          const errors: ValidationError = {
            ...validationErrors,
            required: true,
          };
          validationErrors = errors;
        }
        break;
      case 'minLength':
        if (isEmptyValue(+fieldValue) || !isValidNumber(+value)) {
          return null;
        }
        if (typeof fieldValue === 'string') {
          if (fieldValue.length < +value) {
            const errors: ValidationError = {
              ...validationErrors,
              minLength: { condition: +value, currentValue: fieldValue?.length },
            };
            validationErrors = errors;
          }
        }
        break;
      case 'maxLength':
        if (isEmptyValue(+fieldValue) || !isValidNumber(+value)) {
          return null;
        }
        if (typeof fieldValue === 'string') {
          if (fieldValue.length > +value) {
            const errors: ValidationError = {
              ...validationErrors,
              maxLength: { condition: +value, currentValue: fieldValue?.length },
            };
            validationErrors = errors;
          }
        }
        break;
      case 'min':
        if (isEmptyValue(fieldValue) || isEmptyValue(+value)) {
          return null;
        }
        const minValue = +fieldValue;
        if (!isNaN(minValue) && minValue < +value) {
          const errors: ValidationError = { ...validationErrors, min: { condition: +value, currentValue: minValue } };
          validationErrors = errors;
        }
        break;
      case 'max':
        if (isEmptyValue(fieldValue) || isEmptyValue(+value)) {
          return null;
        }
        const maxValue = +fieldValue;
        if (!isNaN(maxValue) && maxValue > +value) {
          const errors: ValidationError = { ...validationErrors, max: { condition: +value, currentValue: maxValue } };
          validationErrors = errors;
        }
        break;
      case 'validTimeString':
        if (isEmptyValue(fieldValue) || isEmptyValue(+value)) {
          return null;
        }
        const timeValue = fieldValue;
        if (!isValidTimeString(timeValue.toString())) {
          const errors: ValidationError = {
            ...validationErrors,
            validTimeString: { condition: value, currentValue: timeValue },
          };
          validationErrors = errors;
        }
        break;
    }
  });
  return validationErrors;
};

export default validate;
