import React from 'react';

interface Props {
  className?: string;
}

const ArrowDown = ({ className }: Props) => (
  <svg className={className} width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.94449 1.15958C7.98147 1.21549 8 1.27805 8 1.34072C8 1.40321 7.98147 1.46576 7.94449 1.52191L4.35851 5.84035C4.28463 5.95225 4.14784 6.02155 3.99994 6.02155C3.85206 6.02155 3.71529 5.95244 3.64149 5.84035L0.0553584 1.5219C-0.0184412 1.40983 -0.0184412 1.27167 0.0553584 1.15968C0.129416 1.04758 0.266249 0.978393 0.414042 0.978393L7.58596 0.978394C7.73378 0.978394 7.87054 1.04747 7.94449 1.15958Z" fill="#CCD0DB"/>
  </svg>
);

export default ArrowDown;
