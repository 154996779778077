import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import CustomToast, { ToastType } from '../../components/toast/custom-toast';
import { Trans } from 'react-i18next';


export const rtkQueryErrorLogger: Middleware
  = (api: MiddlewareAPI) => (dispatch) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      const errorStatus = action?.payload?.data?._errorCode || '';
      console.log(action?.payload?.status); // 404
      if (action?.payload?.status !== 404) {
        toast.error(
          <CustomToast
            title={<Trans i18nKey={'errorBanner.title'} />}
            message={errorStatus}
            type={ToastType.DANGER}
          />,
          {
            autoClose: 3000,
          },
        );
      }
    }

    return dispatch(action);
  };
